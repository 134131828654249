<template>
  <div class="error-cpmtent-layout">
    <div class="error-content">
      <div class="error-img-operations">
        <img src="../../assets/image/404.svg" />
      </div>
      <div class="error-text-operations">
        <div class="error-text-operations_1">404</div>
        <div class="error-text-operations_2">抱歉，你访问的页面不存在</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage404',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.route-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 999;
}

.error-cpmtent-layout {
  display: flex;
  .error-content {
    margin: auto;
    display: flex;
    height: 650px;
    justify-content: center;
    align-items: center;
    .error-img-operations {
      width: auto;
    }
    .error-text-operations {
      .error-text-operations_1 {
        margin-top: 30%;
        font-size: 8em;
        color: #505050;
      }
      .error-text-operations_2 {
        font-size: x-large;
        color: #707070;
      }
    }
  }
}

.user-table-data {
  margin-top: 20px;
  margin-right: 10px;
}
</style>
